* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

body,html {
  height: 100%
}

body {
  background-color: #f4f7f6;
  color: #333;
  display: flex;
  flex-direction: column;
  font-family: Poppins,sans-serif;
  justify-content: space-between;
  line-height: 1.75
}

.main-content {
  align-items: flex-start;
  flex: 1 1;
  overflow: auto;
  padding: 20px
}

.App,.main-content {
  display: flex;
  justify-content: center
}

.App {
  align-items: center;
  flex-direction: column;
  min-height: 100vh
}

.container-body {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #0000001a;
  margin-top: 30px;
  max-width: 768px;
  min-width: 400px;
  padding: 20px;
  margin-bottom: 100px;
}

header {
  margin-bottom: 40px;
  padding: 20px 0;
  text-align: center;
  width: 100%
}

a,a:visited {
  color: #527cd2
}

.container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 768px;
  min-width: 400px
}

.form-group {
  margin-bottom: 20px
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px
}

input[type=email],input[type=password],input[type=text] {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  width: 100%
}

button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 10px;
  padding: 5px 10px
}

button:hover {
  background-color: #0056b3
}

.shortUrl-display {
  margin-top: 20px;
  text-align: center
}

.shortUrl-link {
  color: #007bff;
  word-break: break-all
}

@media (min-width: 400px) {
  .container {
      max-width:768px;
  }
}

.navbar-container {
  align-items: center;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 0 10px #0000001a;
  color: #fff;
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  padding: 10px 20px
}

.navbar-brand {
  color: #fff;
  font-size: 1.5em;
  text-decoration: none
}

.navbar-links {
  align-items: center;
  display: flex
}

.navbar-link,.navbar-logout-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  text-decoration: none
}

.navbar-link:hover,.navbar-logout-btn:hover {
  text-decoration: underline
}

.navbar-text {
  color: #fff;
  margin-right: 10px
}

.user-dashboard {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #0000001a;
  margin-top: 30px;
  max-width: 768px;
  min-width: 400px;
  padding: 20px
}

.dashboard-section {
  margin-bottom: 30px
}

.dashboard-section h2 {
  border-bottom: 2px solid #eee;
  color: #007bff;
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-align: left
}

ul {
  list-style: none
}

li {
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px
}

li a {
  color: #007bff;
  margin-right: 10px;
  text-decoration: none
}

.form-group input[type=password] {
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  width: 100%
}

button:hover {
  opacity: .9
}

.loading-container {
  padding: 20px;
  text-align: center
}

.message {
  color: #28a745;
  margin-top: 10px
}

.footer {
  background-color: #f8f9fa;
  bottom: 10px;
  box-sizing: border-box;
  bottom: 0px;
  left: 50%;
  padding: 20px;
  position: fixed;
  transform: translateX(-50%);
  width: 100%
}

.footer .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10%
}

.error-message {
  color: red
}